export default class Utilities {
  constructor() {}

  abrirmodal(modal, titulo, body, cerrar, footer, tamano) {
    modal = modal || "#modal";
    titulo = titulo || "";
    body = body || "¡Alerta!";
    footer = footer || "";
    tamano = tamano || "";
    //cerrar  = cerrar || true;
    var backdrop = true,
      keyboard = true;

    if (cerrar !== false) {
      backdrop = true;
      keyboard = true;
    } else {
      backdrop = "static";
      keyboard = false;
    }

    $(modal).find("h5").text(titulo);
    $(modal).removeClass("full-800");
    $(modal).addClass(tamano);
    $(modal).find(".modal-body").html(body);
    $(modal).find(".modal-content").append(footer);

    $(modal).modal({
      backdrop: backdrop,
      keyboard: keyboard,
    });

    $('[data-toggle="tooltip"]').tooltip("show");
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  redirect_blank(url) {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  }

  strip_html_tags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  }

  generarPaginacion(url, total_paginas, pagina_actual, filtro) {
    var html_paginacion = "";
    var filtro_url = "";
    if (filtro != "") {
      filtro_url = "/" + filtro;
    }
    if (pagina_actual > 1) {
      html_paginacion += '<li class="page-item"><a class="page-link" href="' + url + "/" + (pagina_actual - 1) + filtro_url + '"><<</a></li>';
    }
    var desde = pagina_actual - 3;
    if (desde < 1) {
      desde = 1;
    }
    var hasta = pagina_actual + 3;
    if (hasta > total_paginas) {
      hasta = total_paginas;
    }
    for (var i = desde; i <= hasta; i++) {
      var clase = "";
      if (pagina_actual == i) {
        clase = "active";
      }
      html_paginacion += '<li class="page-item ' + clase + '"><a class="page-link item_paginacion" href="' + url + "/" + i + filtro_url + '">' + i + "</a></li>";
    }
    if (pagina_actual < total_paginas) {
      html_paginacion += '<li class="page-item"><a class="page-link" href="' + url + "/" + (total_paginas - 1) + filtro_url + '">>></a></li>';
    }

    $("#pagination-bar").html(html_paginacion);
  }

  generarPaginacionAjax(claseAction, pagination_bar, total_paginas, pagina_actual, filtro) {
    var html_paginacion = "";
    var filtro_url = "";
    if (filtro != "") {
      filtro_url = "/" + filtro;
    }
    if (pagina_actual > 1) {
      html_paginacion += '<li class="page-item"><a class="page-link ' + claseAction + '" href="javascript:void(0);" data-pagina="' + (pagina_actual - 1) + '"><<</a></li>';
    }
    var desde = pagina_actual - 3;
    if (desde < 1) {
      desde = 1;
    }
    var hasta = pagina_actual + 3;
    if (hasta > total_paginas) {
      hasta = total_paginas;
    }
    for (var i = desde; i <= hasta; i++) {
      var clase = "";
      if (pagina_actual == i) {
        clase = "active";
      }
      html_paginacion += '<li class="page-item ' + clase + '"><a class="page-link item_paginacion ' + claseAction + '" href="javascript:void(0);" data-pagina="' + i + '">' + i + "</a></li>";
    }
    if (pagina_actual < total_paginas) {
      html_paginacion += '<li class="page-item"><a class="page-link ' + claseAction + '" href="javascript:void(0);" data-pagina="' + i + '">>></a></li>';
    }
    $("#" + pagination_bar).html(html_paginacion);
  }

  formatFactory(html) {
    function parse(html, tab = 0) {
      //var tab;
      var html = $.parseHTML(html);
      var formatHtml = new String();

      function setTabs() {
        var tabs = new String();

        for (var i = 0; i < tab; i++) {
          tabs += "\t";
        }
        return tabs;
      }

      $.each(html, function (i, el) {
        try {
          if (el.nodeName == "#text") {
            if ($(el).text().trim().length) {
              formatHtml += setTabs() + $(el).text().trim() + "\n";
            }
          } else {
            var innerHTML = $(el).html().trim();
            $(el).html(innerHTML.replace("\n", "").replace(/ +(?= )/g, ""));

            if ($(el).children().length) {
              $(el).html("\n" + parse(innerHTML, tab + 1) + setTabs());
              var outerHTML = $(el).prop("outerHTML").trim();
              //console.log(outerHTML);
              if (outerHTML == "[object Comment]") {
                formatHtml += setTabs() + $(el).html() + "\n";
              } else {
                formatHtml += setTabs() + outerHTML + "\n";
              }
            } else {
              var outerHTML = $(el).prop("outerHTML").trim();
              //console.log(outerHTML);
              if (outerHTML == "[object Comment]") {
                formatHtml += setTabs() + $(el).html() + "\n";
              } else {
                formatHtml += setTabs() + outerHTML + "\n";
              }
            }
          }
        } catch (error) {
          //console.log($(el));
          //console.log($(el).get()[0].data);
          formatHtml += setTabs() + "<!--" + $(el).get()[0].data + "-->\n";
        }
      });

      return formatHtml;
    }

    return parse(html.replace(/(\r\n|\n|\r)/gm, " ").replace(/ +(?= )/g, ""));
  }

  escape_html(str) {
    if (str === null || str === "") return "";
    else str = str.toString();

    var map = {
      /*'&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;',*/
      á: "&aacute;",
      é: "&eacute;",
      í: "&iacute;",
      ó: "&oacute;",
      ú: "&uacute;",
      ñ: "&ntilde;",
      Á: "&Aacute;",
      É: "&Eacute;",
      Í: "&Iacute;",
      Ó: "&Oacute;",
      Ú: "&Uacute;",
      Ñ: "&Ntilde;",
      "¿": "&iquest;",
      "¡": "&iexcl;",
      //"<li>": '<li style="font-size: 14px; margin: 0 0 7px; mso-special-format: bullet; line-height: 24px;"><div style="line-height: 24px;">',
      //"</li>": '</div></li>'
    };
    //var strIndented = this.formatFactory(str);
    var strIndented = str;
    var strX = strIndented.replace(/[áéíóúñÁÉÍÓÚÑ¡¿]/g, function (m) {
      return map[m];
    });
    return strX;
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  getImageSizeInBytes(imgURL) {
    var xhr = $.ajax({
      type: "HEAD",
      url: imgURL,
      success: function (msg) {
        return xhr.getResponseHeader("Content-Length");
      },
    });
  }

  masonryGallery() {
    var $grid = $(".grid").isotope({
      itemSelector: ".grid-item",
      //layoutMode: 'fitRows',
    });

    //debugger;
  }

  magnificPopup() {
    $(".popup-gallery").magnificPopup({
      delegate: "a",
      type: "image",
      tLoading: "Loading image #%curr%...",
      mainClass: "mfp-img-mobile",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item.el.attr("title") + '<small class="d-none">by Marsel Van Oosten</small>';
        },
      },
    });
  }
}
