"use strict";

import Utilities from "./utilities";

var modal = "#modal";
var userObj = null;
var utilities = new Utilities();

export function me() {
  //Obteniendo datos de usuario
  $.ajax({
    url: document.getElementById("api_url").value + "me",
    type: "POST",
    data: {uid: utilities.getCookie("uid")},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      userObj = data;
    },
    complete: function () {},
    error: function (xhr, status, error) {
      var err = JSON.parse(xhr.responseText);
      var titulo = "Aviso",
        body = "<form>\n" + '<div class="form-group mb-3">\n' + '<p>Su sesión ha caducado, porfavor ingrese nuevamente a la plataforma. Será redireccionado al login en <span id=\'timer-redirect-countdown\'>10</span> segundos o ingrese <a class="strong" href="' + document.getElementById("app_url").value + 'ingresar">aquí</a>.</p>\n' + "</div>\n" + "</form>";

      utilities.abrirmodal(modal, titulo, body, false);
      var countdown_redirect = 10;
      document.getElementById("timer-redirect-countdown").innerHTML = "10";
      window.setInterval(function () {
        countdown_redirect = countdown_redirect - 1;
        document.getElementById("timer-redirect-countdown").innerHTML = countdown_redirect;
      }, 1000);
      window.setTimeout(function () {
        window.location.href = document.getElementById("app_url").value + "ingresar";
      }, 10000);
    },
  });
}

//Notificaciones
export function paginationNotificaciones(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
    var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
    $.ajax({
      url: document.getElementById("api_url").value + "notificaciones",
      type: "POST",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.notificaciones, function (i, item) {
          var total_vistas = "---";
          var estado = '<span class="inline-estado text-danger">Borrador</span>';
          var fecha_hora_envio = "---";
          if (item.fecha_hora_envio) {
            fecha_hora_envio = item.fecha_hora_envio;
          }
          var fecha_programacion = "---";
          if (item.fecha_programacion) {
            fecha_programacion = item.fecha_programacion;
          }
          if (item.estado == 2) {
            estado = '<span class="inline-estado inline-estado texto-naranja">Programada</span>';
          } else if (item.estado == 3) {
            estado = '<span class="inline-estado text-success">Enviada</span> <span class="inline-estado_count text-success">(' + item.total_receptores + ")</span>";
            total_vistas = item.total_vistas;
          }
          var html_accion = '<a href="' + $("#app_url").val() + "notificacion/edit/" + item.id + '" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-pencil"></i>' + "</a>";
          if (item.estado == 3) {
            html_accion = '<a href="' + $("#app_url").val() + "notificacion/view/" + item.id + '" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-eye"></i>' + "</a>";
          }
          $("#" + result_element).append(
            "<tr>" +
              "<td>" +
              '<span class="inline-titulo">' +
              item.titulo +
              "</span>" +
              "</td>" +
              "<td>" +
              estado +
              "</td>" +
              "<td>" +
              total_vistas +
              "</td>" +
              "<td>" +
              '<span class="inline-fecha_envio">' +
              '<time datetime="' +
              item.fecha_hora_envio +
              '">' +
              fecha_hora_envio +
              "</time>" +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-fecha_programa">' +
              '<time datetime="' +
              item.fecha_programacion +
              '">' +
              fecha_programacion +
              "</time>" +
              "</span>" +
              "</td>" +
              '<td class="py-0">' +
              '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
              '<li class="px-2">' +
              html_accion +
              "</li>" +
              '<li class="px-2">' +
              '<a href="javascript:void();" data-id="' +
              item.id +
              '" class="btn btn-sm btn-danger texto-blanco action-delete">' +
              '<i class="fa fa-trash"></i>' +
              "</a>" +
              "</li>" +
              "</ul>" +
              "</td>" +
              "</tr>"
          );
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      },
    });
  }
}

export function obtenerNotificaciones(result_element) {
  var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
  var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
  $.ajax({
    url: document.getElementById("api_url").value + "notificaciones",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationNotificaciones(data.total_paginas, result_element);

      $("#" + result_element).html("");
      $.each(data.notificaciones, function (i, item) {
        var total_vistas = "---";
        var estado = '<span class="inline-estado text-danger">Borrador</span>';
        var fecha_hora_envio = "---";
        if (item.fecha_hora_envio) {
          fecha_hora_envio = item.fecha_hora_envio;
        }
        var fecha_programacion = "---";
        if (item.fecha_programacion) {
          fecha_programacion = item.fecha_programacion;
        }
        if (item.estado == 2) {
          estado = '<span class="inline-estado inline-estado texto-naranja">Programada</span>';
        } else if (item.estado == 3) {
          estado = '<span class="inline-estado text-success">Enviada</span> <span class="inline-estado_count text-success">(' + item.total_receptores + ")</span>";
          total_vistas = item.total_vistas;
        }
        var html_accion = '<a href="' + $("#app_url").val() + "notificacion/edit/" + item.id + '" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-pencil"></i>' + "</a>";
        if (item.estado == 3) {
          html_accion = '<a href="' + $("#app_url").val() + "notificacion/view/" + item.id + '" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-eye"></i>' + "</a>";
        }
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-titulo">' +
            item.titulo +
            "</span>" +
            "</td>" +
            "<td>" +
            estado +
            "</td>" +
            "<td>" +
            total_vistas +
            "</td>" +
            "<td>" +
            '<span class="inline-fecha_envio">' +
            '<time datetime="' +
            item.fecha_hora_envio +
            '">' +
            fecha_hora_envio +
            "</time>" +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-fecha_programa">' +
            '<time datetime="' +
            item.fecha_programacion +
            '">' +
            fecha_programacion +
            "</time>" +
            "</span>" +
            "</td>" +
            '<td class="py-0">' +
            '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
            '<li class="px-2">' +
            html_accion +
            "</li>" +
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.id +
            '" class="btn btn-sm btn-danger texto-blanco action-delete">' +
            '<i class="fa fa-trash"></i>' +
            "</a>" +
            "</li>" +
            "</ul>" +
            "</td>" +
            "</tr>"
        );
        // utilities.generarPaginacion($("#app_url").val() + "notificaciones", data.total_paginas, $("#hid_pagina").val(), encodeURIComponent($.trim($("#hid_filtro").val())));
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function crearNotificacion() {
  $.ajax({
    url: document.getElementById("api_url").value + "notificacion",
    type: "POST",
    data: {
      titulo: $("#titulo").val(),
      mensaje_corto: $("#mensaje_corto").val(),
      mensaje: $("#mensaje").val(),
      enlace: $("#enlace").val(),
      fecha_programacion: $("#fecha_programacion").val(),
      divisiones_seleccionadas: $("#divisiones").val(),
      especialidades_seleccionadas: $("#especialidades").val(),
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "notificaciones";
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function actualizarNotificacion() {
  $.ajax({
    url: document.getElementById("api_url").value + "notificacion",
    type: "PUT",
    data: {
      id: $("#hid_id").val(),
      titulo: $("#titulo").val(),
      mensaje_corto: $("#mensaje_corto").val(),
      mensaje: $("#mensaje").val(),
      enlace: $("#enlace").val(),
      fecha_programacion: $("#programar_input").val(),
      divisiones_seleccionadas: $("#divisiones").val(),
      especialidades_seleccionadas: $("#especialidades").val(),
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "notificaciones";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerNotificacion(pantalla) {
  var thisClass = this;
  $.ajax({
    url: document.getElementById("api_url").value + "notificacion/" + $("#hid_id").val(),
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      if (pantalla == "edit") {
        if (response.estado == 3) {
          window.location.href = $("#app_url").val() + "notificacion/view/" + $("#hid_id").val();
        } else {
          $("#titulo").val(response.titulo);
          $("#mensaje_corto").val(response.mensaje_corto);
          $("#mensaje").val(response.mensaje);
          $("#enlace").val(response.enlace);
          $("#vista_por").val(response.total_vistas);
          $("#programar_input").val(response.fecha_programacion);
          thisClass.obtenerSelectorDivisiones("divisiones", "notificacion");
          thisClass.obtenerSelectorEspecialidades("especialidades", "notificacion");
        }
      } else if (pantalla == "view") {
        $("#titulo").html(response.titulo);
        $("#mensaje_corto").html(response.mensaje_corto);
        $("#mensaje").html(response.mensaje);
        $("#enlace").html(response.enlace);
        $("#vista_por").html(response.total_vistas);
        $("#programar_input").html(response.fecha_programacion);
      }
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerSelectorDivisiones(elemento, pantalla) {
  var vid = 0;
  if ($("#hid_id").val() != null) {
    vid = $("#hid_id").val();
  }
  var url = "";
  if (pantalla == "notificacion") {
    url = "notificacion/divisiones/" + vid;
  } else if (pantalla == "cmp") {
    url = "cmp/divisiones/" + vid;
  }
  $.ajax({
    url: document.getElementById("api_url").value + url,
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $.each(response.divisiones, function (i, item) {
        var idesp = response.divisiones[i]["id"],
          desc = response.divisiones[i]["descripcion"];

        $("#" + elemento).append(new Option(desc, idesp));
      });

      setTimeout(function () {
        const displaySelect = new SlimSelect({
          select: "#" + elemento,
        });

        displaySelect.set(response.divisiones_seleccionadas);
      }, 500);
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerSelectorEspecialidades(elemento, pantalla) {
  var vid = 0;
  if ($("#hid_id").val() != null) {
    vid = $("#hid_id").val();
  }
  var url = "";
  if (pantalla == "notificacion") {
    url = "notificacion/especialidades/" + vid;
  } else if (pantalla == "cmp") {
    url = "cmp/especialidades/" + vid;
  }
  $.ajax({
    url: document.getElementById("api_url").value + url,
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      // $('#'+elemento).flexdatalist({
      //     minLength: 1,
      //     multiple: true,
      //     // valueProperty: 'id',
      //     selectionRequired: true,
      //     visibleProperties: ["descripcion"],
      //     searchIn: ["id"],
      //     textProperty: '{descripcion}',
      //     data: response.especialidades,
      //     focusFirstResult: true
      // });

      $.each(response.especialidades, function (i, item) {
        var idesp = response.especialidades[i]["id"],
          desc = response.especialidades[i]["descripcion"];

        $("#" + elemento).append(new Option(desc, idesp));
      });

      setTimeout(function () {
        const displaySelect = new SlimSelect({
          select: "#especialidades",
        });

        console.log(response.especialidades_seleccionadas);

        displaySelect.set(response.especialidades_seleccionadas);
      }, 500);
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function eliminarNotificacion(id) {
  $.ajax({
    url: document.getElementById("api_url").value + "notificacion/" + id,
    type: "DELETE",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>La notificación fue eliminada del sistema.</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerNotificaciones("tbody_notificaciones");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function enviarNotificacion() {
  $.ajax({
    url: document.getElementById("api_url").value + "enviarnotificacion",
    type: "POST",
    data: {
      id: $("#hid_id").val(),
      module: $("#panel_module_action").val(),
      titulo: $("#titulo").val(),
      mensaje_corto: $("#mensaje_corto").val(),
      mensaje: $("#mensaje").val(),
      enlace: $("#enlace").val(),
      divisiones_seleccionadas: $("#divisiones").val(),
      especialidades_seleccionadas: $("#especialidades").val(),
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "notificaciones";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

//Patologias
export function paginationPatologias(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
    var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
    $.ajax({
      url: document.getElementById("api_url").value + "patologias",
      type: "POST",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.patologias, function (i, item) {
          var estado = '<span class="inline-estado text-danger">Borrador</span>';
          var fecha_hora_envio = "---";
          if (item.fecha_hora_envio) {
            fecha_hora_envio = item.fecha_hora_envio;
          }
          var fecha_programacion = "---";
          if (item.fecha_programacion) {
            fecha_programacion = item.fecha_programacion;
          }
          var imagen_url = $("#images_url").val() + "patologias/" + item.imagen;
          var organos_tooltip = "";
          $("#" + result_element).append(
            "<tr>" +
              "<td>" +
              '<span class="inline-titulo">' +
              item.nombre +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-description d-inline-block text-truncate">' +
              item.descripcion_corta +
              "</span>" +
              "</td>" +
              "<td>" +
              '<a class="inline-imagen text-underline" data-toggle="tooltip" data-placement="right" data-html="true" title="<img class=\'img-fluid\' width=\'125\' src=\'' +
              imagen_url +
              "'>\">" +
              item.imagen +
              "</a>" +
              "</td>" +
              "<td>" +
              '<a class="inline-fecha_programa text-underline" href="' +
              item.url +
              '" title="' +
              item.nombre +
              '" target="_blank">' +
              item.url +
              "</a>" +
              "</td>" +
              '<td class="py-0">' +
              '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
              '<li class="px-2">' +
              '<a href="' +
              $("#app_url").val() +
              "patologia/edit/" +
              item.id +
              '" class="btn btn-sm btn-primary texto-blanco action-edit">' +
              '<i class="fa fa-pencil"></i>' +
              "</a>" +
              "</li>" +
              "</ul>" +
              "</td>" +
              "</tr>"
          );
          $('[data-toggle="tooltip"]').tooltip();
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      },
    });
  }
}

export function obtenerPatologias(result_element) {
  var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
  var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
  $.ajax({
    url: document.getElementById("api_url").value + "patologias",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationPatologias(data.total_paginas, result_element);

      $("#" + result_element).html("");
      $.each(data.patologias, function (i, item) {
        var estado = '<span class="inline-estado text-danger">Borrador</span>';
        var fecha_hora_envio = "---";
        if (item.fecha_hora_envio) {
          fecha_hora_envio = item.fecha_hora_envio;
        }
        var fecha_programacion = "---";
        if (item.fecha_programacion) {
          fecha_programacion = item.fecha_programacion;
        }
        var imagen_url = $("#images_url").val() + "patologias/" + item.imagen;
        var organos_tooltip = "";
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-titulo">' +
            item.nombre +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-description d-inline-block text-truncate">' +
            item.descripcion_corta +
            "</span>" +
            "</td>" +
            "<td>" +
            '<a class="inline-imagen text-underline" data-toggle="tooltip" data-placement="right" data-html="true" title="<img class=\'img-fluid\' width=\'125\' src=\'' +
            imagen_url +
            "'>\">" +
            item.imagen +
            "</a>" +
            "</td>" +
            "<td>" +
            '<a class="inline-fecha_programa text-underline" href="' +
            item.url +
            '" title="' +
            item.nombre +
            '" target="_blank">' +
            item.url +
            "</a>" +
            "</td>" +
            '<td class="py-0">' +
            '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
            '<li class="px-2">' +
            '<a href="' +
            $("#app_url").val() +
            "patologia/edit/" +
            item.id +
            '" class="btn btn-sm btn-primary texto-blanco action-edit">' +
            '<i class="fa fa-pencil"></i>' +
            "</a>" +
            "</li>" +
            "</ul>" +
            "</td>" +
            "</tr>"
        );
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function crearPatologia(form) {
  $.ajax({
    url: document.getElementById("api_url").value + "patologia",
    type: "POST",
    data: new FormData(form),
    contentType: false,
    cache: false,
    processData: false,
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "patologias";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function actualizarPatologia(form) {
  $.ajax({
    url: document.getElementById("api_url").value + "update/patologia",
    type: "POST",
    data: new FormData(form),
    contentType: false,
    cache: false,
    processData: false,
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "patologias";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerPatologia() {
  $.ajax({
    url: document.getElementById("api_url").value + "patologia/" + $("#hid_id").val(),
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#nombre_patologia").val(response.nombre);
      $("#descripcion_corta_patologia").val(response.descripcion_corta);
      $("#url_patologia").val(response.url);
      $("#hid_imagen_patologia").val(response.imagen);
      $("#img_patologia").attr("src", $("#images_url").val() + "patologias/" + response.imagen);
      selectorSistemas("id_sistema", response.id_sistema);

      /*$.each(response.organos,function(i, item){
                $("#organos").flexdatalist('add', response.organos[i])
            });*/
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function eliminarPatologia(id) {
  $.ajax({
    url: document.getElementById("api_url").value + "patologia/" + id,
    type: "DELETE",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>La patología fue eliminada del sistema.</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerPatologias("tbody_patologias");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

//Usuarios
export function paginationUsuarios(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
    var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
    $.ajax({
      url: document.getElementById("api_url").value + "usuarios",
      type: "POST",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.usuarios, function (i, item) {
          var fecha_ultimo_ingreso = "---";
          if (item.fecha_ultimo_ingreso) {
            fecha_ultimo_ingreso = item.fecha_ultimo_ingreso;
          }
          var estado = "Eliminado";
          if (item.estado == 1) {
            estado = "Activo";
          }
          if (item.estado == 2) {
            estado = "Inactivo";
          }

          var acciones = "";
          if (item.id > 1) {
            acciones =
              '<td class="py-0">' +
              '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
              '<li class="px-2">' +
              '<a href="' +
              $("#app_url").val() +
              "usuario/edit/" +
              item.id +
              '" class="btn btn-sm btn-primary texto-blanco action-edit">' +
              '<i class="fa fa-pencil"></i>' +
              "</a>" +
              "</li>" +
              '<li class="px-2">' +
              '<a href="javascript:void();" data-id="' +
              item.id +
              '" class="btn btn-sm btn-danger texto-blanco action-delete">' +
              '<i class="fa fa-trash"></i>' +
              "</a>" +
              "</li>" +
              "</ul>" +
              "</td>";
          }
          $("#" + result_element).append(
            "<tr>" +
              "<td>" +
              '<span class="inline-nombre">' +
              item.nombre +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-ultimo_ingreso">' +
              '<time datetime="' +
              item.fecha_ultimo_ingreso +
              '">' +
              fecha_ultimo_ingreso +
              "</time>" +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-description d-inline-block text-truncate inline-rol">' +
              item.rol_nombre +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-estado">' +
              estado +
              "</span>" +
              "</td>" +
              acciones +
              "</tr>"
          );
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      },
    });
  }
}

export function obtenerUsuarios(result_element) {
  var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
  var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
  $.ajax({
    url: document.getElementById("api_url").value + "usuarios",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationUsuarios(data.total_paginas, result_element);

      $("#" + result_element).html("");
      $.each(data.usuarios, function (i, item) {
        var fecha_ultimo_ingreso = "---";
        if (item.fecha_ultimo_ingreso) {
          fecha_ultimo_ingreso = item.fecha_ultimo_ingreso;
        }
        var estado = "Eliminado";
        if (item.estado == 1) {
          estado = "Activo";
        }
        if (item.estado == 2) {
          estado = "Inactivo";
        }

        var acciones = "";
        if (item.id > 1) {
          acciones =
            '<td class="py-0">' +
            '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
            '<li class="px-2">' +
            '<a href="' +
            $("#app_url").val() +
            "usuario/edit/" +
            item.id +
            '" class="btn btn-sm btn-primary texto-blanco action-edit">' +
            '<i class="fa fa-pencil"></i>' +
            "</a>" +
            "</li>" +
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.id +
            '" class="btn btn-sm btn-danger texto-blanco action-delete">' +
            '<i class="fa fa-trash"></i>' +
            "</a>" +
            "</li>" +
            "</ul>" +
            "</td>";
        }
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.nombre +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-ultimo_ingreso">' +
            '<time datetime="' +
            item.fecha_ultimo_ingreso +
            '">' +
            fecha_ultimo_ingreso +
            "</time>" +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-description d-inline-block text-truncate inline-rol">' +
            item.rol_nombre +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-estado">' +
            estado +
            "</span>" +
            "</td>" +
            acciones +
            "</tr>"
        );
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerRoles(result_element) {
  $.ajax({
    url: document.getElementById("api_url").value + "roles",
    type: "GET",
    data: {
      orderby: "created_at",
      order: "DESC",
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      $("#" + result_element).html("");
      $.each(data, function (i, item) {
        $("#" + result_element).append('<option value="' + item.id + '">' + item.nombre + "</option>");
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {},
  });
}

export function obtenerOrganos(result_element, obtenerpatologia = 0) {
  $.ajax({
    url: document.getElementById("api_url").value + "organos",
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      if (obtenerpatologia == 1) {
        obtenerPatologia();
      }
    },
    complete: function () {},
    error: function (xhr, status, error) {},
  });
}

export function obtenerOrganosPorSistema(result_element, id_sistema = 0, id_dispositivo = 0) {
  $.ajax({
    url: document.getElementById("api_url").value + "organos-por-sistema",
    type: "POST",
    data: {
      id_sistema: id_sistema,
      id_dispositivo: id_dispositivo,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      var patologias = document.getElementById("patologias-visitadas").getContext("2d");
      //var arrSistemas = [response.marcacion_sistema_alveolos, response.marcacion_sistema_auditivo, response.marcacion_sistema_respiratorio]
      var arrSistemas = data;
      var arrLabels = [];
      var arrValues = [];
      for (var i = 0; i < arrSistemas.length; i++) {
        arrLabels.push(arrSistemas[i].nombre_es);
        arrValues.push(arrSistemas[i].conteo);
      }
      new Chart(patologias, {
        type: "bar",
        data: {
          labels: arrLabels,
          datasets: [
            {
              label: "",
              data: arrValues,
              backgroundColor: "#4c84ff",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  //drawBorder: false,
                  display: true,
                },
                ticks: {
                  display: true, // hide main x-axis line
                  beginAtZero: true,
                },
                barPercentage: 1.8,
                categoryPercentage: 0.2,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawBorder: true, // hide main y-axis line
                  display: true,
                },
                ticks: {
                  display: true,
                  beginAtZero: true,
                },
              },
            ],
          },
          tooltips: {
            enabled: false,
          },
        },
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {},
  });
}

export function crearUsuario() {
  var estado = 2;
  if ($("#habilitar").prop("checked")) {
    estado = 1;
  }
  $.ajax({
    url: document.getElementById("api_url").value + "usuario",
    type: "POST",
    data: {
      nombre: $("#nombre").val(),
      id_rol: $("#id_rol").val(),
      email: $("#email").val(),
      password: $("#password").val(),
      estado: estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "usuarios";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var err = JSON.parse(xhr.responseText);
      if (err.status == 400) {
        var titulo = "Error",
          body = '<div class="form-group mb-3">\n' + "    <p>" + err.message + "</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      } else {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      }
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function actualizarUsuario() {
  var estado = 2;
  if ($("#habilitar").prop("checked")) {
    estado = 1;
  }
  $.ajax({
    url: document.getElementById("api_url").value + "usuario",
    type: "PUT",
    data: {
      id: $("#hid_id").val(),
      nombre: $("#nombre").val(),
      id_rol: $("#id_rol").val(),
      email: $("#email").val(),
      password: $("#password").val(),
      estado: estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "usuarios";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var err = JSON.parse(xhr.responseText);
      if (err.status == 400) {
        var titulo = "Error",
          body = '<div class="form-group mb-3">\n' + "    <p>" + err.message + "</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      } else {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      }
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerUsuario() {
  $.ajax({
    url: document.getElementById("api_url").value + "usuario/" + $("#hid_id").val(),
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#nombre").val(response.nombre);
      $("#id_rol").val(response.id_rol);
      $("#email").val(response.email);
      $("#password").val(response.password);
      if (response.estado == 1) {
        $("#habilitar").prop("checked", true);
      } else {
        $("#habilitar").prop("checked", false);
      }
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function eliminarUsuario(id) {
  $.ajax({
    url: document.getElementById("api_url").value + "usuario/" + id,
    type: "DELETE",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>El usuario fue eliminada/desactivado del sistema.</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerUsuarios("tbody_usuarios");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerCodigosAccesoXXX(result_element) {
  var pagina_actual = parseInt($("#hid_pagina").val());
  var filtro = $.trim($("#hid_filtro").val());
  var filtro_estado = $("#hid_filtro_estado").val();
  $.ajax({
    url: document.getElementById("api_url").value + "codigosacceso",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      $("#" + result_element).html("");
      $.each(data.codigos_acceso, function (i, item) {
        var estado = "Sin asignar";
        if (item.estado == 2) {
          estado = "Asignado";
        } else if (item.estado == 0) {
          estado = "Eliminado";
        }

        var doctor = "---";
        var nro_colegiatura = "---";
        var identificador = "---";
        if (item.cmpmedico) {
          doctor = "Dr(a). " + item.cmp_nombres + " " + item.cmp_apellidos;
          nro_colegiatura = item.cmpmedico;
          identificador = item.device_id;
        }
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.codigo +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.lote +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            nro_colegiatura +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            doctor +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-estado">' +
            estado +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-description d-inline-block text-truncate inline-rol">' +
            item.updated_at +
            "</span>" +
            "</td>" +
            "</tr>"
        );
        var filtro_url = "";
        if (filtro != "") {
          filtro_url += "&filtro=" + filtro;
        }
        if (filtro_estado != "") {
          filtro_url += "&filtro_estado=" + filtro_estado;
        }
        var html_paginacion = "";
        if (pagina_actual > 1) {
          $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
        }
        var desde = pagina_actual - 3;
        if (desde < 1) {
          desde = 1;
        }
        var hasta = pagina_actual + 3;
        if (hasta > data.total_paginas) {
          hasta = data.total_paginas;
        }
        for (var i = desde; i <= hasta; i++) {
          var clase = "";
          if (pagina_actual == i) {
            clase = "active";
          }
          html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + i + filtro_url + '">' + i + "</a></li>";
        }
        if (pagina_actual < data.total_paginas) {
          $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
        }
        $("#pagination-bar").html(html_paginacion);
      });
    },
    complete: function () {
      //$('#loading').hide();
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function paginationCodigosAcceso(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt($("#hid_pagina").val());
    var filtro = $.trim($("#hid_filtro").val());
    var filtro_estado = $("#hid_filtro_estado").val();

    $.ajax({
      url: document.getElementById("api_url").value + "codigosacceso",
      type: "POST",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
        filtro_estado: filtro_estado,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.codigos_acceso, function (i, item) {
          var estado = "Sin asignar";
          if (item.estado == 2) {
            estado = "Asignado";
          } else if (item.estado == 0) {
            estado = "Eliminado";
          }

          var doctor = "---";
          var nro_colegiatura = "---";
          var identificador = "---";
          if (item.cmpmedico) {
            doctor = "Dr(a). " + item.cmp_nombres + " " + item.cmp_apellidos;
            nro_colegiatura = item.cmpmedico;
            identificador = item.device_id;
          }
          $("#" + result_element).append(
            "<tr>" +
              "<td>" +
              '<span class="inline-nombre">' +
              item.codigo +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-nombre">' +
              item.lote +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-nombre">' +
              nro_colegiatura +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-nombre">' +
              doctor +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-estado">' +
              estado +
              "</span>" +
              "</td>" +
              "<td>" +
              '<span class="inline-description d-inline-block text-truncate inline-rol">' +
              item.updated_at +
              "</span>" +
              "</td>" +
              "</tr>"
          );
          var filtro_url = "";
          if (filtro != "") {
            filtro_url += "&filtro=" + filtro;
          }
          if (filtro_estado != "") {
            filtro_url += "&filtro_estado=" + filtro_estado;
          }
          var html_paginacion = "";
          if (pagina_actual > 1) {
            $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
          }
          var desde = pagina_actual - 3;
          if (desde < 1) {
            desde = 1;
          }
          var hasta = pagina_actual + 3;
          if (hasta > data.total_paginas) {
            hasta = data.total_paginas;
          }
          for (var i = desde; i <= hasta; i++) {
            var clase = "";
            if (pagina_actual == i) {
              clase = "active";
            }
            html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + i + filtro_url + '">' + i + "</a></li>";
          }
          if (pagina_actual < data.total_paginas) {
            $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
          }
          $("#pagination-bar").html(html_paginacion);
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      },
    });
  }
}

export function obtenerCodigosAcceso(result_element) {
  var pagina_actual = parseInt($("#hid_pagina").val());
  var filtro = $.trim($("#hid_filtro").val());
  var filtro_estado = $("#hid_filtro_estado").val();

  $.ajax({
    url: document.getElementById("api_url").value + "codigosacceso",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationCodigosAcceso(data.total_paginas, result_element);
      $("#" + result_element).html("");
      $.each(data.codigos_acceso, function (i, item) {
        var estado = "Sin asignar";
        if (item.estado == 2) {
          estado = "Asignado";
        } else if (item.estado == 0) {
          estado = "Eliminado";
        }

        var doctor = "---";
        var nro_colegiatura = "---";
        var identificador = "---";
        if (item.cmpmedico) {
          doctor = "Dr(a). " + item.cmp_nombres + " " + item.cmp_apellidos;
          nro_colegiatura = item.cmpmedico;
          identificador = item.device_id;
        }
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.codigo +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.lote +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            nro_colegiatura +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            doctor +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-estado">' +
            estado +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-description d-inline-block text-truncate inline-rol">' +
            item.updated_at +
            "</span>" +
            "</td>" +
            "</tr>"
        );
        var filtro_url = "";
        if (filtro != "") {
          filtro_url += "&filtro=" + filtro;
        }
        if (filtro_estado != "") {
          filtro_url += "&filtro_estado=" + filtro_estado;
        }
        var html_paginacion = "";
        if (pagina_actual > 1) {
          $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
        }
        var desde = pagina_actual - 3;
        if (desde < 1) {
          desde = 1;
        }
        var hasta = pagina_actual + 3;
        if (hasta > data.total_paginas) {
          hasta = data.total_paginas;
        }
        for (var i = desde; i <= hasta; i++) {
          var clase = "";
          if (pagina_actual == i) {
            clase = "active";
          }
          html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + i + filtro_url + '">' + i + "</a></li>";
        }
        if (pagina_actual < data.total_paginas) {
          $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "codigoacceso?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
        }
        $("#pagination-bar").html(html_paginacion);
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function paginationCuenta(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt($("#hid_pagina").val());
    var filtro = $.trim($("#hid_filtro").val());
    var filtro_estado = $.trim($("#hid_filtro_estado").val());

    $.ajax({
      url: document.getElementById("api_url").value + "obtenercuentasdispositivos",
      type: "POST",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
        filtro_estado: filtro_estado,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.cuentas, function (i, item) {
          var doctor = "Dr(a). " + item.nombres + " " + item.apellidos;
          var estado = "Inactivo";
          if (item.activado == 1) {
            estado = "Activo";
          }
          var fecha_activacion = "---";
          if (item.fecha_activacion) {
            fecha_activacion = item.fecha_activacion;
          }
          var codigo_acceso = "---";
          if (item.device_code) {
            codigo_acceso = item.device_code;
          }
          var modelo = "---";
          if (item.modelo) {
            modelo = item.modelo;
          }
          var item_html =
            "<tr>" +
            "<td>" +
            '<span class="inline-nombre">' +
            item.codigo +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            doctor +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            codigo_acceso +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-description d-inline-block text-truncate inline-rol">' +
            fecha_activacion +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="inline-nombre">' +
            modelo +
            "</span>" +
            "</td>" +
            '<td class="py-0">' +
            '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">';
          if (item.disp_id > 0) {
            item_html +=
              '<li class="px-2">' +
              '<a href="javascript:void();" data-id="' +
              item.codigo +
              '" data-dispositivo="' +
              item.disp_id +
              '" alt="Quitar Acceso" title="Quitar Acceso" class="btn btn-sm btn-danger texto-blanco action-delete">' +
              '<i class="fa fa-trash"></i>' +
              "</a>" +
              "</li>" +
              '<li class="px-2">' +
              '<a href="javascript:void();" data-id="' +
              item.codigo +
              '" data-dispositivo="' +
              item.disp_id +
              '" class="btn btn-sm btn-primary texto-blanco action-view">' +
              '<i class="fa fa-eye"></i>' +
              "</a>" +
              "</li>";
          }
          item_html += "</ul>" + "</td>" + "</tr>";
          $("#" + result_element).append(item_html);
          var filtro_url = "";
          if (filtro != "") {
            filtro_url += "&filtro=" + filtro;
          }
          var html_paginacion = "";
          if (pagina_actual > 1) {
            $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
          }
          var desde = pagina_actual - 3;
          if (desde < 1) {
            desde = 1;
          }
          var hasta = pagina_actual + 3;
          if (hasta > data.total_paginas) {
            hasta = data.total_paginas;
          }
          for (var i = desde; i <= hasta; i++) {
            var clase = "";
            if (pagina_actual == i) {
              clase = "active";
            }
            html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + i + filtro_url + '">' + i + "</a></li>";
          }
          if (pagina_actual < data.total_paginas) {
            $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
          }
          $("#pagination-bar").html(html_paginacion);
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        if (xhr.status == 401) {
          var titulo = "Aviso",
            body = "<form>\n" + '<div class="form-group mb-3">\n' + '<p>Su sesión ha caducado, porfavor ingrese nuevamente a la plataforma. Será redireccionado al login en <span id=\'timer-redirect-countdown\'>10</span> segundos o ingrese <a class="strong" href="' + document.getElementById("app_url").value + 'ingresar">aquí</a>.</p>\n' + "</div>\n" + "</form>";

          utilities.abrirmodal(modal, titulo, body, false);
          var countdown_redirect = 10;
          document.getElementById("timer-redirect-countdown").innerHTML = "10";
          window.setInterval(function () {
            countdown_redirect = countdown_redirect - 1;
            document.getElementById("timer-redirect-countdown").innerHTML = countdown_redirect;
          }, 1000);
          window.setTimeout(function () {
            window.location.href = document.getElementById("app_url").value + "ingresar";
          }, 10000);
        } else {
          var titulo = "",
            body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
          setTimeout(function () {
            utilities.abrirmodal(modal, titulo, body, true);
          }, 500);
        }
      },
    });
  }
}

export function obtenerCuenta(result_element) {
  var pagina_actual = parseInt($("#hid_pagina").val());
  var filtro = $.trim($("#hid_filtro").val());
  var filtro_estado = $.trim($("#hid_filtro_estado").val());

  $.ajax({
    url: document.getElementById("api_url").value + "obtenercuentasdispositivos",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationCuenta(data.total_paginas, result_element);

      $("#" + result_element).html("");
      $.each(data.cuentas, function (i, item) {
        var doctor = "Dr(a). " + item.nombres + " " + item.apellidos;
        var estado = "Inactivo";
        if (item.activado == 1) {
          estado = "Activo";
        }
        var fecha_activacion = "---";
        if (item.fecha_activacion) {
          fecha_activacion = item.fecha_activacion;
        }
        var codigo_acceso = "---";
        if (item.device_code) {
          codigo_acceso = item.device_code;
        }
        var modelo = "---";
        if (item.modelo) {
          modelo = item.modelo;
        }
        var item_html =
          "<tr>" +
          "<td>" +
          '<span class="inline-nombre">' +
          item.codigo +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          doctor +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          codigo_acceso +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-description d-inline-block text-truncate inline-rol">' +
          fecha_activacion +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          modelo +
          "</span>" +
          "</td>" +
          '<td class="py-0">' +
          '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">';
        if (item.disp_id > 0) {
          item_html +=
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.codigo +
            '" data-dispositivo="' +
            item.disp_id +
            '" alt="Quitar Acceso" title="Quitar Acceso" class="btn btn-sm btn-danger texto-blanco action-delete">' +
            '<i class="fa fa-trash"></i>' +
            "</a>" +
            "</li>" +
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.codigo +
            '" data-dispositivo="' +
            item.disp_id +
            '" class="btn btn-sm btn-primary texto-blanco action-view">' +
            '<i class="fa fa-eye"></i>' +
            "</a>" +
            "</li>";
        }
        item_html += "</ul>" + "</td>" + "</tr>";
        $("#" + result_element).append(item_html);
        var filtro_url = "";
        if (filtro != "") {
          filtro_url += "&filtro=" + filtro;
        }
        var html_paginacion = "";
        if (pagina_actual > 1) {
          $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
        }
        var desde = pagina_actual - 3;
        if (desde < 1) {
          desde = 1;
        }
        var hasta = pagina_actual + 3;
        if (hasta > data.total_paginas) {
          hasta = data.total_paginas;
        }
        for (var i = desde; i <= hasta; i++) {
          var clase = "";
          if (pagina_actual == i) {
            clase = "active";
          }
          html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + i + filtro_url + '">' + i + "</a></li>";
        }
        if (pagina_actual < data.total_paginas) {
          $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
        }
        $("#pagination-bar").html(html_paginacion);
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      if (xhr.status == 401) {
        var titulo = "Aviso",
          body = "<form>\n" + '<div class="form-group mb-3">\n' + '<p>Su sesión ha caducado, porfavor ingrese nuevamente a la plataforma. Será redireccionado al login en <span id=\'timer-redirect-countdown\'>10</span> segundos o ingrese <a class="strong" href="' + document.getElementById("app_url").value + 'ingresar">aquí</a>.</p>\n' + "</div>\n" + "</form>";

        utilities.abrirmodal(modal, titulo, body, false);
        var countdown_redirect = 10;
        document.getElementById("timer-redirect-countdown").innerHTML = "10";
        window.setInterval(function () {
          countdown_redirect = countdown_redirect - 1;
          document.getElementById("timer-redirect-countdown").innerHTML = countdown_redirect;
        }, 1000);
        window.setTimeout(function () {
          window.location.href = document.getElementById("app_url").value + "ingresar";
        }, 10000);
      } else {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      }
    },
  });
}

export function obtenerCuentasDispositivos(result_element) {
  var pagina_actual = parseInt($("#hid_pagina").val());
  var filtro = $.trim($("#hid_filtro").val());
  var filtro_estado = $.trim($("#hid_filtro_estado").val());

  alert("kflsdjfjksd");

  $.ajax({
    url: document.getElementById("api_url").value + "obtenercuentasdispositivos",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      $("#" + result_element).html("");
      $.each(data.cuentas, function (i, item) {
        var doctor = "Dr(a). " + item.nombres + " " + item.apellidos;
        var estado = "Inactivo";
        if (item.activado == 1) {
          estado = "Activo";
        }
        var fecha_activacion = "---";
        if (item.fecha_activacion) {
          fecha_activacion = item.fecha_activacion;
        }
        var codigo_acceso = "---";
        if (item.device_code) {
          codigo_acceso = item.device_code;
        }
        var modelo = "---";
        if (item.modelo) {
          modelo = item.modelo;
        }
        var item_html =
          "<tr>" +
          "<td>" +
          '<span class="inline-nombre">' +
          item.codigo +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          doctor +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          codigo_acceso +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-description d-inline-block text-truncate inline-rol">' +
          fecha_activacion +
          "</span>" +
          "</td>" +
          "<td>" +
          '<span class="inline-nombre">' +
          modelo +
          "</span>" +
          "</td>" +
          '<td class="py-0">' +
          '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">';
        if (item.disp_id > 0) {
          item_html +=
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.codigo +
            '" data-dispositivo="' +
            item.disp_id +
            '" alt="Quitar Acceso" title="Quitar Acceso" class="btn btn-sm btn-danger texto-blanco action-delete">' +
            '<i class="fa fa-trash"></i>' +
            "</a>" +
            "</li>" +
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.codigo +
            '" data-dispositivo="' +
            item.disp_id +
            '" class="btn btn-sm btn-primary texto-blanco action-view">' +
            '<i class="fa fa-eye"></i>' +
            "</a>" +
            "</li>";
        }
        item_html += "</ul>" + "</td>" + "</tr>";
        $("#" + result_element).append(item_html);
        var filtro_url = "";
        if (filtro != "") {
          filtro_url += "&filtro=" + filtro;
        }
        var html_paginacion = "";
        if (pagina_actual > 1) {
          $("#prev-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (pagina_actual - 1) + filtro_url + '"><i class="fa fa-angle-double-left font-13"></i></a>');
        }
        var desde = pagina_actual - 3;
        if (desde < 1) {
          desde = 1;
        }
        var hasta = pagina_actual + 3;
        if (hasta > data.total_paginas) {
          hasta = data.total_paginas;
        }
        for (var i = desde; i <= hasta; i++) {
          var clase = "";
          if (pagina_actual == i) {
            clase = "active";
          }
          html_paginacion += '<li class="page-item page-number ' + clase + '"><a class="page-abbott item_paginacion" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + i + filtro_url + '">' + i + "</a></li>";
        }
        if (pagina_actual < data.total_paginas) {
          $("#next-pagination").html('<a class="page-abbott" href="' + document.getElementById("app_url").value + "cuentas?pagina=" + (data.total_paginas - 1) + filtro_url + '"><i class="fa fa-angle-double-right font-13"></i></a>');
        }
        $("#pagination-bar").html(html_paginacion);
        //utilities.generarPaginacionAjax("item-pagination-cuentas","pagination-bar-cuentas", data.total_paginas, $("#hid_pagina_cuentas").val(), encodeURIComponent($.trim($("#hid_filtro").val())));
      });
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      if (xhr.status == 401) {
        var titulo = "Aviso",
          body = "<form>\n" + '<div class="form-group mb-3">\n' + '<p>Su sesión ha caducado, porfavor ingrese nuevamente a la plataforma. Será redireccionado al login en <span id=\'timer-redirect-countdown\'>10</span> segundos o ingrese <a class="strong" href="' + document.getElementById("app_url").value + 'ingresar">aquí</a>.</p>\n' + "</div>\n" + "</form>";

        utilities.abrirmodal(modal, titulo, body, false);
        var countdown_redirect = 10;
        document.getElementById("timer-redirect-countdown").innerHTML = "10";
        window.setInterval(function () {
          countdown_redirect = countdown_redirect - 1;
          document.getElementById("timer-redirect-countdown").innerHTML = countdown_redirect;
        }, 1000);
        window.setTimeout(function () {
          window.location.href = document.getElementById("app_url").value + "ingresar";
        }, 10000);
      } else {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      }
    },
  });
}

export function crearLoteCodigos(cantidad) {
  $.ajax({
    url: document.getElementById("api_url").value + "crearlote",
    type: "POST",
    data: {
      cantidad: cantidad,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Se crearon " + response.cantidad + " códigos en el lote " + response.lote + ".</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerCodigosAcceso("tbody_codigos");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerDetalleUsuario(cmp, dispositivo, sistema) {
  $.ajax({
    url: document.getElementById("api_url").value + "obtenerdetalleusuario",
    type: "POST",
    data: {
      cmp: cmp,
      dispositivo: dispositivo,
      sistema: sistema,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      var modelo = "---";
      if (response.modelo) {
        modelo = response.modelo;
      }
      $("#device").html(modelo);
      $("#nombre_dr").html("Dr(a) " + response.nombres + " " + response.apellidos);
      $("#nombre_medico").html(response.nombres + " " + response.apellidos);
      $("#numero_colegiatura").html(response.codigo);
      $("#correo_electronico").html(response.email);
      $("#telefono").html(response.telefono);
      $("#ultimo_acceso").html(response.fecha_ultimo_ingreso);
      $("#fecha_creacion").html(response.created_at);
      $("#total_accesos").html(response.total_accesos);
      $("#ultimos_accesos").html(response.total_accesos_mes);
      //Aqui estaba la grafdica

      var estadisticas = document.getElementById("card-chart4").getContext("2d");
      var arrUltimosMeses = [];
      var hoy = new Date();
      for (var i = 1; i <= 6; i++) {
        if (hoy.getMonth() + 1 == 1) {
          arrUltimosMeses.push("Ene");
        } else if (hoy.getMonth() + 1 == 2) {
          arrUltimosMeses.push("Feb");
        } else if (hoy.getMonth() + 1 == 3) {
          arrUltimosMeses.push("Mar");
        } else if (hoy.getMonth() + 1 == 4) {
          arrUltimosMeses.push("Abr");
        } else if (hoy.getMonth() + 1 == 5) {
          arrUltimosMeses.push("May");
        } else if (hoy.getMonth() + 1 == 6) {
          arrUltimosMeses.push("Jun");
        } else if (hoy.getMonth() + 1 == 7) {
          arrUltimosMeses.push("Jul");
        } else if (hoy.getMonth() + 1 == 8) {
          arrUltimosMeses.push("Ago");
        } else if (hoy.getMonth() + 1 == 9) {
          arrUltimosMeses.push("Set");
        } else if (hoy.getMonth() + 1 == 10) {
          arrUltimosMeses.push("Oct");
        } else if (hoy.getMonth() + 1 == 11) {
          arrUltimosMeses.push("Nov");
        } else if (hoy.getMonth() + 1 == 12) {
          arrUltimosMeses.push("Dic");
        }
        hoy.setMonth(hoy.getMonth() - 1);
      }
      new Chart(estadisticas, {
        type: "line",
        defaultFontColor: "white",
        data: {
          labels: arrUltimosMeses.reverse(),
          datasets: [
            {
              label: "Sesiones últimos 6 meses",
              data: response.reporte_accesos,
              backgroundColor: "transparent",
              borderColor: "#20a8d8",
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                },
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                },
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                },
              },
            ],
          },
          title: {
            display: false,
            text: "Estadísticas de uso",
          },
        },
      });
    },
    complete: function () {
      $("#datos-medico .cargando").fadeOut();
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerImagenesCompartidas(cmp, dispositivo, pagina_actual, sistema) {
  $("#cargar_mas i").show();
  $("#galeria .cargando").show();

  $.ajax({
    url: document.getElementById("api_url").value + "cargarimagenes",
    type: "POST",
    data: {
      cmp: cmp,
      dispositivo: dispositivo,
      pagina: pagina_actual,
      sistema: sistema,
      filtro: "",
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      var urlimages = response.url,
        elemento = "",
        contenedor = "#capturas_compartidas";
      $("#total_imagenes").html(response.total_imagenes);
      if (response.imagenes.length == 0) {
        $("#hid_pagina_imagenes").val(pagina_actual - 1);
      }
      $.each(response.imagenes, function (i, item) {
        elemento +=
          '<div class="col-6 col-md-3 col-lg-2 grid-item websites" data-category="websites">\n' +
          '   <div class="card">\n' +
          '      <a href="' +
          urlimages +
          "/" +
          item.imagen +
          '" title="' +
          item.created_at +
          '">\n' +
          '           <img class="card-img-top" src="' +
          urlimages +
          "/" +
          item.imagen +
          '" alt="' +
          item.created_at +
          '">\n' +
          "      </a>\n" +
          '      <div class="card-body p-2">\n' +
          '           <p class="mb-0"><small>' +
          item.created_at +
          "</small></p>\n" +
          "      </div>\n" +
          "   </div>\n" +
          "</div>";
      });
      $(contenedor).append(elemento);
    },
    complete: function () {
      setTimeout(function () {
        utilities.masonryGallery();
        utilities.magnificPopup();
        $(".grid").isotope("reloadItems").isotope();
      }, 500);
      $("#cargar_mas i").hide();
      $("#galeria .cargando").fadeOut();
    },
    error: function (xhr, status, error) {
      $("#galeria .cargando").fadeOut();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerReporteMarcacion(result_element, cmp, dispositivo) {
  var pagina_actual = parseInt(document.getElementById("hid_pagina_marcacion").value);
  $.ajax({
    url: document.getElementById("api_url").value + "reportemarcacion",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      cmp: cmp,
      dispositivo: dispositivo,
      pagina: pagina_actual,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      $("#" + result_element).html("");
      $.each(data.marcaciones, function (i, item) {
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="dispositivo">' +
            item.device_id +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="key_sesion">' +
            item.session_key +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="accion">' +
            item.accion +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="id_elemento">' +
            item.elementoId +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="elemento">' +
            item.elemento +
            "</span>" +
            "</td>" +
            "<td>" +
            '<span class="creado_el"><time datetime="' +
            item.created_at +
            '">' +
            item.created_at +
            "</time></span>" +
            "</td>" +
            "</tr>"
        );
        utilities.generarPaginacionAjax("item-pagination-marcacion", "pagination-bar-marcacion", data.total_paginas, $("#hid_pagina_marcacion").val(), encodeURIComponent($.trim($("#hid_filtro").val())));
      });
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function desactivarDoctor(cmp) {
  $.ajax({
    url: document.getElementById("api_url").value + "desactivardoctor",
    type: "POST",
    data: {
      cmp: cmp,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>El Doctor con CMP " + cmp + " fue desactivado del sistema.</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerCuentasDispositivos("tbody_cuentas");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function exportarCodigos() {
  var filtro = $.trim($("#hid_filtro").val());
  var filtro_estado = $("#hid_filtro_estado").val();
  $.ajax({
    url: document.getElementById("api_url").value + "export-codigos",
    type: "POST",
    data: {
      orderby: "created_at",
      order: "DESC",
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      if (response.status == 201) {
        window.open(document.getElementById("images_url").value + "tmp/" + response.filename, "_blank");
      } else {
        alert("Ocurrió un error. Contáctese con el administrador.");
      }
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

//CMP
export function paginationCmp(pages = 20, result_element) {
  $("#light-pagination").pagination({
    pages: pages,
    cssStyle: "compact-theme",
  });

  setTimeout(() => {
    $(document).on("click", ".page-link", function (e) {
      e.preventDefault();
      var number = $(this).attr("href");
      codeAjax(result_element, number);
    });
  }, 750);

  function codeAjax(result_element, number) {
    var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
    var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
    var filtro_estado = encodeURIComponent($.trim(document.getElementById("hid_filtro_estado").value));
    $.ajax({
      url: document.getElementById("api_url").value + "cmps",
      type: "GET",
      data: {
        orderby: "created_at",
        order: "DESC",
        pagina: number,
        filtro: filtro,
        filtro_estado: filtro_estado,
      },
      headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
      dataType: "json",
      success: function (data) {
        $("#" + result_element).html("");
        $.each(data.doctores, function (i, item) {
          var total_vistas = "---";
          var estado = '<span class="inline-estado text-danger">Borrador</span>';
          var fecha_hora_envio = "---";
          if (item.fecha_hora_envio) {
            fecha_hora_envio = item.fecha_hora_envio;
          }
          var fecha_programacion = "---";
          if (item.fecha_programacion) {
            fecha_programacion = item.fecha_programacion;
          }
          if (item.estado == 2) {
            estado = '<span class="inline-estado inline-estado texto-naranja">Programada</span>';
          } else if (item.estado == 3) {
            estado = '<span class="inline-estado text-success">Enviada</span> <span class="inline-estado_count text-success">(' + item.total_receptores + ")</span>";
            total_vistas = item.total_vistas;
          }
          var html_accion = '<a href="' + $("#app_url").val() + "cmp/edit/" + item.id + '" title="Editar" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-pencil"></i>' + "</a>";
          if (item.estado == 3) {
            html_accion = '<a href="' + $("#app_url").val() + "cmp/view/" + item.id + '" title="Detalle" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-eye"></i>' + "</a>";
          }
          $("#" + result_element).append(
            "<tr>" +
              "<td>" +
              '<span class="inline-titulo">' +
              item.codigo +
              "</span>" +
              "</td>" +
              "<td>" +
              item.nombres +
              "</td>" +
              "<td>" +
              item.apellidos +
              "</td>" +
              "<td>" +
              item.dni +
              "</td>" +
              "<td>" +
              item.email +
              "</td>" +
              '<td class="py-0">' +
              '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
              '<li class="px-2">' +
              html_accion +
              "</li>" +
              '<li class="px-2">' +
              '<a href="javascript:void();" data-id="' +
              item.id +
              '" title="Eliminar" class="btn btn-sm btn-danger texto-blanco action-delete">' +
              '<i class="fa fa-trash"></i>' +
              "</a>" +
              "</li>" +
              "</ul>" +
              "</td>" +
              "</tr>"
          );
        });
      },
      complete: function () {},
      error: function (xhr, status, error) {
        $("#modal").modal("hide");
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
        setTimeout(function () {
          utilities.abrirmodal(modal, titulo, body, true);
        }, 500);
      },
    });
  }
}

export function obtenerCMPs(result_element) {
  var pagina_actual = parseInt(document.getElementById("hid_pagina").value);
  var filtro = encodeURIComponent($.trim(document.getElementById("hid_filtro").value));
  var filtro_estado = encodeURIComponent($.trim(document.getElementById("hid_filtro_estado").value));
  $.ajax({
    url: document.getElementById("api_url").value + "cmps",
    type: "GET",
    data: {
      orderby: "created_at",
      order: "DESC",
      pagina: pagina_actual,
      filtro: filtro,
      filtro_estado: filtro_estado,
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      paginationCmp(data.total_paginas, result_element);

      $("#" + result_element).html("");
      $.each(data.doctores, function (i, item) {
        var total_vistas = "---";
        var estado = '<span class="inline-estado text-danger">Borrador</span>';
        var fecha_hora_envio = "---";
        if (item.fecha_hora_envio) {
          fecha_hora_envio = item.fecha_hora_envio;
        }
        var fecha_programacion = "---";
        if (item.fecha_programacion) {
          fecha_programacion = item.fecha_programacion;
        }
        if (item.estado == 2) {
          estado = '<span class="inline-estado inline-estado texto-naranja">Programada</span>';
        } else if (item.estado == 3) {
          estado = '<span class="inline-estado text-success">Enviada</span> <span class="inline-estado_count text-success">(' + item.total_receptores + ")</span>";
          total_vistas = item.total_vistas;
        }
        var html_accion = '<a href="' + $("#app_url").val() + "cmp/edit/" + item.id + '" title="Editar" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-pencil"></i>' + "</a>";
        if (item.estado == 3) {
          html_accion = '<a href="' + $("#app_url").val() + "cmp/view/" + item.id + '" title="Detalle" class="btn btn-sm btn-primary texto-blanco action-edit">' + '<i class="fa fa-eye"></i>' + "</a>";
        }
        $("#" + result_element).append(
          "<tr>" +
            "<td>" +
            '<span class="inline-titulo">' +
            item.codigo +
            "</span>" +
            "</td>" +
            "<td>" +
            item.nombres +
            "</td>" +
            "<td>" +
            item.apellidos +
            "</td>" +
            "<td>" +
            item.dni +
            "</td>" +
            "<td>" +
            item.email +
            "</td>" +
            '<td class="py-0">' +
            '<ul class="list-unstyled d-flex flex-row justify-content-center list-actions">' +
            '<li class="px-2">' +
            html_accion +
            "</li>" +
            '<li class="px-2">' +
            '<a href="javascript:void();" data-id="' +
            item.id +
            '" title="Eliminar" class="btn btn-sm btn-danger texto-blanco action-delete">' +
            '<i class="fa fa-trash"></i>' +
            "</a>" +
            "</li>" +
            "</ul>" +
            "</td>" +
            "</tr>"
        );
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function crearCMP() {
  $.ajax({
    url: document.getElementById("api_url").value + "cmp",
    type: "POST",
    data: {
      nombres: $.trim($("#nombres").val()),
      apellidos: $.trim($("#apellidos").val()),
      codigo: $.trim($("#codigo").val()),
      tipo_colegio: $.trim($("#tipo_colegio").val()),
      dni: $.trim($("#dni").val()),
      telefono: $.trim($("#telefono").val()),
      email: $.trim($("#email").val()),
      divisiones_seleccionadas: $("#divisiones").val(),
      especialidades_seleccionadas: $("#especialidades").val(),
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "cmps";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");

      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      if (xhr.responseJSON != null) {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>" + xhr.responseJSON.message + "</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      }
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function actualizarCMP() {
  $.ajax({
    url: document.getElementById("api_url").value + "cmp",
    type: "PUT",
    data: {
      id: $("#hid_id").val(),
      nombres: $.trim($("#nombres").val()),
      apellidos: $.trim($("#apellidos").val()),
      codigo: $.trim($("#codigo").val()),
      tipo_colegio: $.trim($("#tipo_colegio").val()),
      dni: $.trim($("#dni").val()),
      telefono: $.trim($("#telefono").val()),
      email: $.trim($("#email").val()),
      divisiones_seleccionadas: $("#divisiones").val(),
      especialidades_seleccionadas: $("#especialidades").val(),
    },
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      window.location.href = $("#app_url").val() + "cmps";
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");

      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      if (xhr.responseJSON != null) {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>" + xhr.responseJSON.message + "</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      }
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function obtenerCMP(pantalla) {
  var thisClass = this;
  $.ajax({
    url: document.getElementById("api_url").value + "cmp/" + $("#hid_id").val(),
    type: "GET",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#nombres").val(response.nombres);
      $("#apellidos").val(response.apellidos);
      $("#tipo_colegio").val(response.tipo_colegio);
      $("#codigo").val(response.codigo);
      $("#dni").val(response.dni);
      $("#telefono").val(response.telefono);
      $("#email").val(response.email);
      thisClass.obtenerSelectorDivisiones("divisiones", "cmp");
      thisClass.obtenerSelectorEspecialidades("especialidades", "cmp");
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function eliminarCMP(id) {
  $.ajax({
    url: document.getElementById("api_url").value + "cmp/" + id,
    type: "DELETE",
    data: {},
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>El doctor(cmp) fue eliminada del sistema.</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
      obtenerCMPs("tbody_cmp");
    },
    complete: function () {},
    error: function (xhr, status, error) {
      $("#modal").modal("hide");

      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      if (xhr.responseJSON != null) {
        var titulo = "",
          body = '<div class="form-group mb-3">\n' + "    <p>" + xhr.responseJSON.message + "</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      }
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

export function importarCMPs(form) {
  $.ajax({
    url: document.getElementById("api_url").value + "importarcmp",
    type: "POST",
    data: new FormData(form),
    contentType: false,
    cache: false,
    processData: false,
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (response) {
      $("#modal").modal("hide");
      var titulo = "Importación de CMP",
        body =
          '<div class="form-group mb-3">\n' +
          "    <p>- Se ha importado correctamente " +
          response.total_ingresados +
          " nuevos cmps</p>\n" +
          "    <p>- Se encontraron " +
          response.total_repetidos +
          " ya existentes en la plataforma</p>\n" +
          "    <p>- Se encontraron " +
          response.total_fallidos +
          " registros fallidos</p>\n" +
          "  </div>\n" +
          '  <div class="form-group clearfix mb-0">\n' +
          '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' +
          "  </div>";
      $(".cargando").fadeOut();
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
    complete: function () {
      //$('#modal').modal('hide');
    },
    error: function (xhr, status, error) {
      //$('#loading').hide();
      $("#modal").modal("hide");
      var titulo = "",
        body = '<div class="form-group mb-3">\n' + "    <p>Ocurrió un error, inténtelo más tarde o revise su conexión a internet. Si persiste contáctate con soporte@beta.pe</p>\n" + "  </div>\n" + '  <div class="form-group clearfix mb-0">\n' + '    <button type="button" class="float-right btn btn-azul px-3 font-w400" data-dismiss="modal">Aceptar</button>\n' + "  </div>";
      $(".cargando").fadeOut();
      setTimeout(function () {
        utilities.abrirmodal(modal, titulo, body, true);
      }, 500);
    },
  });
}

//Sistemas
export function selectorSistemas(result_element, id_selected) {
  $.ajax({
    url: document.getElementById("api_url").value + "sistema",
    type: "GET",
    headers: {Authorization: "Bearer " + utilities.getCookie("abbott_token")},
    dataType: "json",
    success: function (data) {
      $("#" + result_element).html('<option value="">-- Seleccione Sistema --</option>');
      $.each(data, function (i, item) {
        if (id_selected == item.id) {
          $("#" + result_element).append('<option value="' + item.id + '" selected>' + item.nombre + "</option>");
        } else {
          $("#" + result_element).append('<option value="' + item.id + '">' + item.nombre + "</option>");
        }
      });
    },
    complete: function () {},
    error: function (xhr, status, error) {},
  });
}
