/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
var apicalls = require("./apicalls.js");

$(document).ready(function () {
  apicalls.me();

  var hid_id = $("#hid_id").val();
  var hid_dispositivo = $("#hid_dispositivo").val();
  if ($("#panel_module_action").val() == "list") {
    //Obtener lista de usuarios
    apicalls.obtenerCuenta("tbody_cuentas");
  } else if ($("#panel_module_action").val() == "detalle") {
    apicalls.obtenerDetalleUsuario(hid_id, hid_dispositivo, $("#select_sistema").val());
    apicalls.obtenerImagenesCompartidas(hid_id, hid_dispositivo, $("#hid_pagina_imagenes").val(), $("#select_sistema").val());
  }

  //Cargar más imágenes
  $(document).on("click", "#cargar_mas", function (event) {
    event.preventDefault();
    $("#hid_pagina_imagenes").val(parseInt($("#hid_pagina_imagenes").val()) + 1);
    apicalls.obtenerImagenesCompartidas(hid_id, hid_dispositivo, $("#hid_pagina_imagenes").val());
  });

  $(document).on("change", "#filtro_estado", function (event) {
    event.preventDefault();
    make_filter();
  });

  $("#buscar_cuenta").keyup(function (e) {
    e.preventDefault();
    if (e.keyCode === 13) {
      make_filter();
    }
  });

  $(document).on("click", "#btn_filtrar_cuentas", function (event) {
    event.preventDefault();
    make_filter();
  });

  function make_filter() {
    $("#hid_filtro").val($.trim($("#buscar_cuenta").val()));
    $("#hid_filtro_estado").val($.trim($("#filtro_estado").val()));
    if ($.trim($("#hid_filtro").val()) != "" && $.trim($("#hid_filtro_estado").val()) != "") {
      window.location.href = $("#app_url").val() + "cuentas?pagina=1&filtro_estado=" + $("#hid_filtro_estado").val() + "&filtro=" + $("#hid_filtro").val();
    } else if ($.trim($("#hid_filtro").val()) == "" && $.trim($("#hid_filtro_estado").val()) != "") {
      window.location.href = $("#app_url").val() + "cuentas?pagina=1&filtro_estado=" + $("#hid_filtro_estado").val();
    } else {
      window.location.href = $("#app_url").val() + "cuentas?pagina=1";
    }
  }

  $(document).on("change", "#select_sistema", function (event) {
    event.preventDefault();
    apicalls.obtenerOrganosPorSistema("tbody_bitacora", $(this).val(), $("#hid_dispositivo").val());
  });

  //Ver detalle
  $(document).on("click", ".action-view", function (event) {
    event.preventDefault();
    window.location.href = $("#app_url").val() + "cuenta/view/" + $(this).attr("data-id") + "?dispositivo=" + $(this).attr("data-dispositivo");
  });

  //Ver detalle
  $(document).on("click", ".action-editar", function (event) {
    event.preventDefault();
    window.location.href = $("#app_url").val() + "cuenta/edit/" + $(this).attr("data-id") + "?dispositivo=" + $(this).attr("data-dispositivo");
  });

  //Ver mas detalle
  $(document).on("click", ".ver-detalle-marcacion", function (event) {
    event.preventDefault();
    apicalls.obtenerReporteMarcacion("tbody_bitacora", hid_id, hid_dispositivo);
  });

  //Item paginacion cuentas
  $(document).on("click", ".item-pagination-cuentas", function (event) {
    event.preventDefault();
    $("#hid_pagina_cuentas").val($(this).attr("data-pagina"));
    apicalls.obtenerCuenta("tbody_cuentas");
  });

  //Desactivar doctor y caducar sus tokens y sesiones
  $(document).on("click", ".action-delete", function (event) {
    event.preventDefault();
    apicalls.desactivarDoctor($(this).attr("data-id"));
  });

  //Item paginacion reporte marcacion
  $(document).on("click", ".item-pagination-marcacion", function (event) {
    event.preventDefault();
    $("#hid_pagina_marcacion").val($(this).attr("data-pagina"));
    apicalls.obtenerReporteMarcacion("tbody_bitacora", hid_id, hid_dispositivo);
  });
});
